<template>
  <v-card elevation="0" class="overflow-y-auto" color="#1a1b1d" style="margin-left: 5px" min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`" :style="$vuetify.breakpoint.smAndDown
      ? `border-left: `
      : `border-left:1px solid gray`
      ">
    <v-card-text>
      <div>
        <div class="d-flex align-end mt-4 headText">
          <h2 class="headTextProject" :style="fontSize">SuperLab</h2>
          <v-spacer></v-spacer>
          <v-btn small text @click="$router.go(-1)" class="orange--text" style="border: 2px solid orange">Back</v-btn>
        </div>
      </div>

      <div class="font-weight-light black--text caption mb-2">
        <p class="desc">(responsive design)</p>
      </div>

      <v-row>
        <v-col class="main-text">
          <br />
          <span class="textInsideProject"> Language: </span>
          JavaScript<br />
          <span class="textInsideProject"> Framework: </span>
          Vue + Vuex (State management pattern + library)<br />
          <span class="textInsideProject">UI Library:</span> Vuetify
          <br />
          <span class="textInsideProject">Database:</span> NoSQL <br />
          <span class="textInsideProject">Server:</span>
          Firebase (hosting, authentication, cloud firestore, storage) <br />
          <span class="textInsideProject">devDependencies: </span>cli, babel,
          eslint, router, vuex, sass, vuetify
          <br />
          <span class="textInsideProject">Short info: </span>Developed a
          Single-page application (SPA) enabling lab clients to access
          information about services, check analysis costs, and view results
          online. The app also offers lab workers an efficient way to publish
          news, update service prices, add or remove services, and manipulate
          application text.

          <div class="d-flex justify-center git-gallery-live">
            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange"
              href="https://github.com/kirk7183/superLab" target="_blank">
              <i class="orange--text fas fa-bullseye pa-0"> GitHub</i>
            </v-btn>

            <br />

            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange" @click="openGallery(0)">
              <i class="orange--text fas fa-bullseye pa-0"> Gallery</i>
            </v-btn>

            <br />

            <v-btn small text class="orange--text ma-sm-2 ma-0" style="border: 2px solid orange"
              href="https://farmakon-ff747.web.app/" target="_blank">
              <i class="orange--text fas fa-bullseye pa-0"> Live demo</i>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Gallery -->
    <!-- <v-row class="ma-0">
      <v-col class="px-4">
        <h2 class="">Gallery</h2>
        <v-container class="mt-5" grid-list-md>
          <v-layout row wrap>
            <v-flex
              xs6
              sm4
              lg2
              v-for="(image, i) in images"
              :key="i"
              class="d-flex justify-center"
            >
              <img
                v-lazy="image.src"
                :style="picSize"
                style="cursor: pointer"
                @click="openGallery(i)"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-row> -->

    <!-- Import component LightBox za prikaz galerije sa kontrolama levo i desno-->
    <LightBox ref="lightbox" :media="images" :show-caption="true" :show-light-box="false" :closable="true" />
  </v-card>
</template>

<script>
  import LightBox from "vue-image-lightbox";
  require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

  export default {
    components: { LightBox },
    data() {
      return {
        images: [
          {
            thumb: require("@/assets/projects/SuperLab/slika-2.png"),
            src: require("@/assets/projects/SuperLab/slika-2.png"),
            caption:
              "<h4 class='pt-2'>Information and what the laboratory offers</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-3.png"),
            src: require("@/assets/projects/SuperLab/slika-3.png"),
            caption:
              "<h4 class='pt-2'>Information and what the laboratory offers</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-4.png"),
            src: require("@/assets/projects/SuperLab/slika-4.png"),
            caption: "<h4 class='pt-2'>Frequently asked questions</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-5.png"),
            src: require("@/assets/projects/SuperLab/slika-5.png"),
            caption:
              "<h4 class='pt-2'>Results online - Enter the account number</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-6.png"),
            src: require("@/assets/projects/SuperLab/slika-6.png"),
            caption: "<h4 class='pt-2'>Calculating the price of services</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-7.png"),
            src: require("@/assets/projects/SuperLab/slika-7.png"),
            caption: "<h4 class='pt-2'>About page</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-8.png"),
            src: require("@/assets/projects/SuperLab/slika-8.png"),
            caption:
              "<h4 class='pt-2'>Login - with a 'login as guest' for testing</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-9.png"),
            src: require("@/assets/projects/SuperLab/slika-9.png"),
            caption: "<h4 class='pt-2'>Menu for lab workers</h4>",
          },
          {
            thumb: require("@/assets/projects/SuperLab/slika-10.png"),
            src: require("@/assets/projects/SuperLab/slika-10.png"),
            caption: "<h4 class='pt-2'>New lab-related news</h4>",
          },
        ],
      };
    },
    computed: {
      fontSize() {
        switch (this.$vuetify.breakpoint.name) {
          // case "xs":
          // case "sm":
          //   return { "font-size": "21px !important" };
          default:
            return {
              "font-size": "1.3em",
              "letter-spacing": "0.05em",
              "text-transform": "uppercase",
            };
        }
      },
      picSize() {
        switch (this.$vuetify.breakpoint.name) {
          case "lg":
            return { width: "95%", height: "80px" };
          default:
            //tj. xs, sm, md
            return { width: "auto", height: "96px", overflow: "hidden" };
        }
      },
    },
    methods: {
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
      },
    },
  };
</script>

<style></style>
